<template>
  <div class="huiwen-container huiwen-layout-flex-center">
    <a-pagination
        :current="page_now"
        :total="total"
        :page-size="pageSize"
        :page-size-options="pageSizeOptions"
        :show-size-changer="showSizeChanger"
        :show-quick-jumper="showQuickJumper"
        @change="handlePageChange"
        @showSizeChange="handleSizeChange"
    />
  </div>
</template>

<script>
import { Pagination } from 'ant-design-vue';

export default {
  name: "cmy-page",
  components: {
    'a-pagination': Pagination
  },
  props: {
    pageSizeOptions: { // ✅ 允许传入可选的 pageSize 选项
      type: Array,
      default: () => ['10', '20', '30', '40','50','60'] // 默认选项
    },
    page_now: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    pageSize: {
      type: Number,
      default: 10
    },
    showSizeChanger: {
      type: Boolean,
      default: true
    },
    showQuickJumper: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handlePageChange(page) {
      this.$emit('update:page_now', page);
    },
    handleSizeChange(current, size) {
      this.$emit('update:pageSize', size);
    }
  }
};
</script>

<style scoped>
.huiwen-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
</style>
